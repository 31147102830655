/*Fonts*/
@import url('https://fonts.googleapis.com/css?family=Nunito|Roboto:400,700');

$headerfont: 'Roboto', sans-serif;
$textfont: 'Nunito', sans-serif;

/*Site Colors*/
$blue: #2832aa;
$ltblue: #5bc3eb;
$dkblue: darken($blue, 15%);
$orange: #ff6b35;
$yellow: #eac435;
$green: #03cea4;
$red: #fb4d3d;
$grey: #dadad9;
$white: #fff;
$linkcolor: #f06449;

/*Colors in Use*/
$bgcolor: $blue;
$headlinecolor: $grey;
$textcolor: $white;
$hover: $yellow;

/*Mixins*/
@mixin color-change($delay) {
  animation: colorchange 10s infinite;
  animation-delay: $delay;
}
@keyframes colorchange {
  0% {color: $headlinecolor;}
  20% {color: $orange;}
  40% {color: $yellow;}
  60% {color: $red;}
  80% {color: $green;}
  100% {color: $headlinecolor;}
}

/*VARIABLES*/
$transspeed: 350ms;

/*GENERAL*/
body {
  background-color: $bgcolor;
}

h1, h2, h3, h4, h5, h6, {
  color: $textcolor;
  font-family: $headerfont;
  text-transform: uppercase;
}

h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.25em;
}

p {
  color: $textcolor;
  font-family: $textfont;
  font-size: 1.25em;
}

a {
  color: $linkcolor;
  text-decoration: none;
  transition: all $transspeed ease-in-out;

  &:hover {
    color: $yellow;
  }
}

ul {
  list-style-type: none;
  padding: 10px;
  margin: 0;
  
  li {
    display: inline;
    margin: .25em;
  }
}

.btn-wrap {
  text-align: center;
  .link-btn {
    background-color: $linkcolor;
    color: $white;
    border-radius: 5%;
    padding: 7.5px 10px;
    text-transform: uppercase;
    font-size: .85em;
    line-height: .95em;
    font-weight: bold;
    border: 2px transparent solid;
    transition: all $transspeed ease-in-out;
    display: inline-block;

    &:hover {
      border-color: $linkcolor;
      background-color: $white;
      color: $linkcolor;
    }
  }
}

/*BEGIN STYLES*/

.App {
  text-align: center;
  position: relative;
}

.App-header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  color: white;
  padding: 1em 1em 1em 1em;
  background-color: $bgcolor;
  display: block;
  width: 100%;
  text-align: left;
}

#header-name {
  border: 5px $textcolor solid;
  padding: 5px 15px;
  line-height: 2em;
  display: inline-block;
  z-index: 999;
  position: relative;
  
  .header-name-letter {
    font-size: 2em;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .lname-start {
    margin-left: 10px;
  }
  /*Name Animation*/
  #name-j {
    @include color-change(0);
  }
  #name-a {
    @include color-change(50ms);
  }
  #name-r {
    @include color-change(100ms);
  }
  #name-e {
    @include color-change(150ms);
  }
  #name-d {
    @include color-change(200ms);
  }
  #name-h {
    @include color-change(250ms);
    margin-left: 10px;
  }
  #name-a-l {
    @include color-change(300ms);
  }
  #name-g {
    @include color-change(350ms);
  }
  #name-u {
    @include color-change(400ms);
  }
  #name-e-l {
    @include color-change(450ms);
  }
}

#site-nav {
  margin-left: 50px;
}

.menu-wrap {
  display: flex;
  justify-content: space-around;
  height: 100%;

  .menu-item {
    display: inline;
    margin: 10px;
    a {
      color: $textcolor;
      text-transform: uppercase;
      font-weight: bold;

    }
  }
}

.content-wrap {
  max-width: 85%;
  margin: 0 auto;
}

#about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;

  #bio {
    max-width: 750px;  
    padding: 1em 1em;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 95%;
   
    #intro {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1em;

      h1 {
        font-size: 4em;
        line-height: 1em;
        align-self: flex-end;
        font-weight: 700;
        margin-bottom: -10px;
      }

      #headshot {
        width: 200px;
        height: auto;
        align-self: center;
      }
    } 
  }

  #contact-list {
    text-align: left;
    margin: 1em 0;
    padding: 0;
    position: relative;
    z-index: 99;

    .list-item {
      
      .list-item-name {
        border: 5px $textcolor solid;
        padding: 5px 15px;
        font-family: $headerfont;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: .05em;
        color: $textcolor;
        background-color: transparent;
        transition: all $transspeed ease-in-out;

        &:hover {
          border-color: $hover;
          color: $hover;
        }
      }
    }
  }

  #portfolio-link {
    position: absolute;
    bottom: 2rem;
  }

  #background-effect {
    
     canvas {
       position: absolute;
       width: 100%;
       height: 100%;
       left: 0;
       top: 0;
     }
   }
}

.site-section {
  padding: 5em 0;
}

#portfolio {
  #portfolio-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    height: 100%;

    .portfolio-item {
      width: 48%;

      .img-link-wrap {
        display: block;
        overflow: hidden;
        width: 100%;
        height: auto;
        border: 5px transparent solid;
        position: relative;
        &:hover {
          border-color: $hover;
        }
        &:hover .portfolio-description {
          bottom: 0;
        }

        .portfolio-description {
          position: absolute;
          display: block;
          margin: 0;
          width: 100%;
          background-color: $dkblue;
          padding: 1em 0;
          transition: all $transspeed ease-in-out;
          bottom: -100%;
          font-size: .75em;

          p {
            padding: 0 .75em;
          }
          .portfolio-title {
            
          }
        }

        img {
          width: 100%;
          height: auto;
        }
        
      }

      
    }
  }
}

#blog-frontpage {
  padding: 2em 0;
}

/*Media Queries*/
@media only screen and (max-width: 800px) {

  .App-header {
    position: relative;
  }

  #about { 
    height: auto;

    #bio {
     
      #intro {
        display: flex;
        justify-content: center;
  
        h1 {
          align-self: center;
        }
      } 
    }
  }


  #portfolio {
    #portfolio-wrap {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
  
      .portfolio-item {
        width: 95%;
        margin-bottom: 1em;
  
        .img-link-wrap {
          overflow: visible;
        
          .portfolio-description {
            position: relative;
            display: block;
            bottom:0; 
            margin-top: -3px;
          }
          
        }
  
        
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  #about {
    #bio {
      #intro {
        h1 {
          font-size: 2em;
        }
      }
    }
  }
}