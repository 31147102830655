@import url(https://fonts.googleapis.com/css?family=Nunito|Roboto:400,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Fonts*/
/*Site Colors*/
/*Colors in Use*/
/*Mixins*/
@-webkit-keyframes colorchange {
  0% {
    color: #dadad9; }
  20% {
    color: #ff6b35; }
  40% {
    color: #eac435; }
  60% {
    color: #fb4d3d; }
  80% {
    color: #03cea4; }
  100% {
    color: #dadad9; } }
@keyframes colorchange {
  0% {
    color: #dadad9; }
  20% {
    color: #ff6b35; }
  40% {
    color: #eac435; }
  60% {
    color: #fb4d3d; }
  80% {
    color: #03cea4; }
  100% {
    color: #dadad9; } }

/*VARIABLES*/
/*GENERAL*/
body {
  background-color: #2832aa; }

h1, h2, h3, h4, h5, h6 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase; }

h1 {
  font-size: 2.5em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.25em; }

p {
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 1.25em; }

a {
  color: #f06449;
  text-decoration: none;
  -webkit-transition: all 350ms ease-in-out;
  transition: all 350ms ease-in-out; }
  a:hover {
    color: #eac435; }

ul {
  list-style-type: none;
  padding: 10px;
  margin: 0; }
  ul li {
    display: inline;
    margin: .25em; }

.btn-wrap {
  text-align: center; }
  .btn-wrap .link-btn {
    background-color: #f06449;
    color: #fff;
    border-radius: 5%;
    padding: 7.5px 10px;
    text-transform: uppercase;
    font-size: .85em;
    line-height: .95em;
    font-weight: bold;
    border: 2px transparent solid;
    -webkit-transition: all 350ms ease-in-out;
    transition: all 350ms ease-in-out;
    display: inline-block; }
    .btn-wrap .link-btn:hover {
      border-color: #f06449;
      background-color: #fff;
      color: #f06449; }

/*BEGIN STYLES*/
.App {
  text-align: center;
  position: relative; }

.App-header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  color: white;
  padding: 1em 1em 1em 1em;
  background-color: #2832aa;
  display: block;
  width: 100%;
  text-align: left; }

#header-name {
  border: 5px #fff solid;
  padding: 5px 15px;
  line-height: 2em;
  display: inline-block;
  z-index: 999;
  position: relative;
  /*Name Animation*/ }
  #header-name .header-name-letter {
    font-size: 2em;
    text-transform: uppercase;
    font-weight: bold; }
  #header-name .lname-start {
    margin-left: 10px; }
  #header-name #name-j {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 0;
            animation-delay: 0; }
  #header-name #name-a {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 50ms;
            animation-delay: 50ms; }
  #header-name #name-r {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 100ms;
            animation-delay: 100ms; }
  #header-name #name-e {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 150ms;
            animation-delay: 150ms; }
  #header-name #name-d {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms; }
  #header-name #name-h {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
    margin-left: 10px; }
  #header-name #name-a-l {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 300ms;
            animation-delay: 300ms; }
  #header-name #name-g {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 350ms;
            animation-delay: 350ms; }
  #header-name #name-u {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms; }
  #header-name #name-e-l {
    -webkit-animation: colorchange 10s infinite;
            animation: colorchange 10s infinite;
    -webkit-animation-delay: 450ms;
            animation-delay: 450ms; }

#site-nav {
  margin-left: 50px; }

.menu-wrap {
  display: flex;
  justify-content: space-around;
  height: 100%; }
  .menu-wrap .menu-item {
    display: inline;
    margin: 10px; }
    .menu-wrap .menu-item a {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold; }

.content-wrap {
  max-width: 85%;
  margin: 0 auto; }

#about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative; }
  #about #bio {
    max-width: 750px;
    padding: 1em 1em;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 95%; }
    #about #bio #intro {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1em; }
      #about #bio #intro h1 {
        font-size: 4em;
        line-height: 1em;
        align-self: flex-end;
        font-weight: 700;
        margin-bottom: -10px; }
      #about #bio #intro #headshot {
        width: 200px;
        height: auto;
        align-self: center; }
  #about #contact-list {
    text-align: left;
    margin: 1em 0;
    padding: 0;
    position: relative;
    z-index: 99; }
    #about #contact-list .list-item .list-item-name {
      border: 5px #fff solid;
      padding: 5px 15px;
      font-family: "Roboto", sans-serif;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: .05em;
      color: #fff;
      background-color: transparent;
      -webkit-transition: all 350ms ease-in-out;
      transition: all 350ms ease-in-out; }
      #about #contact-list .list-item .list-item-name:hover {
        border-color: #eac435;
        color: #eac435; }
  #about #portfolio-link {
    position: absolute;
    bottom: 2rem; }
  #about #background-effect canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.site-section {
  padding: 5em 0; }

#portfolio #portfolio-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  height: 100%; }
  #portfolio #portfolio-wrap .portfolio-item {
    width: 48%; }
    #portfolio #portfolio-wrap .portfolio-item .img-link-wrap {
      display: block;
      overflow: hidden;
      width: 100%;
      height: auto;
      border: 5px transparent solid;
      position: relative; }
      #portfolio #portfolio-wrap .portfolio-item .img-link-wrap:hover {
        border-color: #eac435; }
      #portfolio #portfolio-wrap .portfolio-item .img-link-wrap:hover .portfolio-description {
        bottom: 0; }
      #portfolio #portfolio-wrap .portfolio-item .img-link-wrap .portfolio-description {
        position: absolute;
        display: block;
        margin: 0;
        width: 100%;
        background-color: #19206c;
        padding: 1em 0;
        -webkit-transition: all 350ms ease-in-out;
        transition: all 350ms ease-in-out;
        bottom: -100%;
        font-size: .75em; }
        #portfolio #portfolio-wrap .portfolio-item .img-link-wrap .portfolio-description p {
          padding: 0 .75em; }
      #portfolio #portfolio-wrap .portfolio-item .img-link-wrap img {
        width: 100%;
        height: auto; }

#blog-frontpage {
  padding: 2em 0; }

/*Media Queries*/
@media only screen and (max-width: 800px) {
  .App-header {
    position: relative; }
  #about {
    height: auto; }
    #about #bio #intro {
      display: flex;
      justify-content: center; }
      #about #bio #intro h1 {
        align-self: center; }
  #portfolio #portfolio-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center; }
    #portfolio #portfolio-wrap .portfolio-item {
      width: 95%;
      margin-bottom: 1em; }
      #portfolio #portfolio-wrap .portfolio-item .img-link-wrap {
        overflow: visible; }
        #portfolio #portfolio-wrap .portfolio-item .img-link-wrap .portfolio-description {
          position: relative;
          display: block;
          bottom: 0;
          margin-top: -3px; } }

@media only screen and (max-width: 400px) {
  #about #bio #intro h1 {
    font-size: 2em; } }

